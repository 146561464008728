<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else>
      <!-- <div @click="$router.push('/profile')" class="py-2 px-3">
        <font-awesome-icon icon="chevron-left" class="mr-2" /> Back
      </div> -->
      <NewslistComponent :list="newsList" />
      <infinite-loading
        @infinite="infiniteHandler"
        ref="infiniteLoading"
      ></infinite-loading>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import NewslistComponent from "@/components/news/NewslistComponent";
import InfiniteLoading from "vue-infinite-loading";
import OtherLoading from "@/components/other/OtherLoading";
export default {
  components: {
    Breadcrumb,
    NewslistComponent,
    InfiniteLoading,
    OtherLoading
  },
  data() {
    return {
      breadcrumb: [
        {
          text: "ข่าวสาร",
          to: "/profile"
        }
      ],
      filter: {
        page: 1,
        take: 5
      },
      newsList: [],
      isLoading: true
    };
  },
  metaInfo() {
    return {
      title: this.$route.query.title || "News"
    };
  },
  async created() {
    await this.getNewsList(false);
  },
  methods: {
    async getNewsList(scrollFlag, $state) {
      if (!scrollFlag) {
        this.filter.page = 1;
        this.newsList = [];
      }
      await this.$axios
        .post(
          `${process.env.VUE_APP_API}/api/v1/MonthlyPrivilege/list`,
          this.filter
        )
        .then(async data => {
          this.isLoading = false;
          if (data.result == 1) {
            if (scrollFlag) {
              if (data.detail.detail.length == 0) {
                if ($state) $state.complete();
              } else {
                this.newsList = [...this.newsList, ...data.detail.detail];
                if ($state) $state.loaded();
              }
            } else {
              this.newsList = data.detail.detail;
              if ($state) $state.loaded();
              this.onScrollToTop();
            }
          } else {
            if ($state) $state.complete();
          }
        });
    },
    onScrollToTop() {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 500);
    },
    async infiniteHandler($state) {
      setTimeout(async () => {
        this.filter.page++;
        await this.getNewsList(true, $state);
      }, 500);
    }
  }
};
</script>

<style lang="scss" scoped>
// .content-page{
//   margin-top: 80px;
// //   min-height: 100vh;
// }
::v-deep .infinite-status-prompt {
  display: none;
}
</style>
